import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJournalJumbotron,
  CBreadCrumb,
  CJournalLead,
  CProfileMedia,
  CJournalText,
  CWideImgMedia,
  CFloatingImg,
  CBtnList,
  LWrap,
  CQuoteBox,
  CSingleImg,
  CChapterHeading,
  CLabelTextList,
  CJournalMedia,
  CMedia,
  CTileImg,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "pickup",
              path: "/pickup/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <CJournalJumbotron
        data={{
          label: "ザ ロイヤルパークホテル 京都四条",
          date: "2023年03月07日",
          title: (
            <>
              春の彩りを見つけに、
              <br />
              京都四条から楽しむちょっぴり贅沢な母娘旅
            </>
          ),
          img: {
            src: "/assets/images/journal/article07/kv.png",
          },
          imgSp: {
            src: "/assets/images/journal/article07/kv__sp.png",
          },
        }}
      />
      <div className="u_pb60">
        <LWrap size="small">
          <CJournalLead
            lead={
              <>
                肌にふれる空気も少しずつ暖かくなってきた今日この頃。春の訪れはもう間近？ふと気がつくと、また京都へ行きたくなる季節がやってきた。花々や緑が色づきはじめ、神社の朱色やただよう空気までも鮮明に美しく感じる春の京都で、母娘の親子二人旅はいかが？ちょっぴり贅沢を楽しむ時間を「ザ
                ロイヤルパークホテル 京都四条」とその周辺で体験してみた。
              </>
            }
          />
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article07/img_chapter01.png",
            },
            imgSp: {
              src: "/assets/images/journal/article07/img_chapter01__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "01",
            },
            title: (
              <>
                <span>意外と穴場！“京都四条”を</span>
                <span>起点に旅をスタート</span>
              </>
            ),
          }}
        />
        <LWrap size="small">
          <CJournalText
            data={{
              text: (
                <>
                  久しぶりに母との二人旅。今回の旅の起点に選んだのは、京都随一の繁華街エリア四条河原町から徒歩10分ほどの四条駅。便利な穴場スポットらしい。ビジネスビルが立ち並び、小道に入ればおしゃれなレストランがひっそりとたたずむ大人な雰囲気。それでいて、京都駅から2駅4分ほど、地下鉄やバスを利用すれば各観光エリアへのアクセスも良いという、まさに最適な場所だ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter01_01.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    アクティブな旅もいいけれど、せっかく女同士の旅なのだから、ゆったりと美しいものにふれてみたい。とまず目指したのは、京都国立近代美術館。京都市左京区岡崎に位置するこのエリアは、四条から地下鉄と徒歩で20分ほど。京都国立近代美術館をはじめ、京都市京セラ美術館、ロームシアター、平安神宮などさまざまな施設が集まる文化エリアだ。朱色の大鳥居がひときわ目を引く平安神宮でお参りしてから、いざ美術館へと向かう。
                  </p>
                  <p>
                    1963年に開館した京都国立近代美術館は歴史ある美術館。モダンな外観はプリツカー賞を受賞した建築家・槇文彦によるもので、館内のアートは日本画や現代アート、写真、工芸など幅広いジャンルが展示され一度に鑑賞できる。
                  </p>
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter01_02.png",
              alt: "",
            }}
            caption={
              <>
                ロビーフロアに飾られた大きなアート作品。桜の開花時期には目の前の琵琶湖疏水沿いにある桜並木が満開になる
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  この日訪れたのは、開催中のテキスタイルの展覧会『リュイユ―フィンランドのテキスタイル：トゥオマス・ソパネン・コレクション』。「ちょうど観たいと思っていたの」、テキスタイル好きの母の言葉に「そうだと思ってた」と嬉しくなりつつ、コレクションギャラリーへ足を踏み入れる。彩豊かな春に、さらに美しい“色”を添えてくれる鮮やかなテキスタイルにうっとり。思い思いにアート作品と向き合える空間や時間は、まさに母娘旅にふさわしいスポットだ。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter01_03.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter01_04.png",
                  alt: "",
                },
              },
            ]}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter01_05.png",
              alt: "",
            }}
            caption={
              <>
                リュイユ展はコレクションギャラリー内で開催中。コレクション展と一緒に楽しめる（写真はコレクション展）
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  「京都に来て、アート体験ってなんだか贅沢ね」なんて、いつもとは違うゆったりとした時間に満足げな様子の母。心ゆくまでアートを鑑賞したら、1Fのミュージアムショップでお土産を購入するのも忘れずに。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">京都国立近代美術館</h3>
            <p className="u_mb0">
              住所：京都市左京区岡崎円勝寺町26-1
              <br />
              HP：
              <a
                href="https://www.momak.go.jp"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                https://www.momak.go.jp
              </a>
              <br />
              Instagram：@momakyoto
            </p>
            <p className="u_mb0 u_fs12 u_referenceMark">
              『リュイユ―フィンランドのテキスタイル：トゥオマス・ソパネン・コレクション』
            </p>
            <p>展覧期間：2023年1月28日（土）～4月16日（日）</p>
          </CQuoteBox>
          <CJournalText
            data={{
              text: (
                <>
                  定番観光スポットも捨てがたいけれど、はんなりと京都らしい街並み散策も新しい発見があるかも。ホテルを出て、ぷらぷらと街を散策してみると歩いて5分ほど、京都燈籠町の通りに現れた日本最古の絵具屋、上羽絵惣にたどり着いた。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter01_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  ここは創業270年以上、江戸時代から続く日本画用の絵具の製造・卸を行っている上羽絵惣の本店。奥に工房と事務所、手前に店舗という町屋づくりで、歴史と京の趣ある建物の中で、美しい伝統色が生み出されている。実は、上羽絵惣ではホタテの貝殻を原料とした「胡粉」を使った、「胡粉ネイル」というマニキュアも人気らしい。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter01_07.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  「水茜色」「桃花色」「紫苑」…どれも日本独自の色をベースとして名付けられている美しい和名で、日本の伝統的な色とあって、どこか奥ゆかしさもある。店内に並ぶネイルを手に取りながら、「どの色がいいかな？」「お母さん、この色なんてどう？」と二人の会話も盛り上がる。日本の美を感じる、京の彩りを旅の土産として持ち帰ろう。
                </>
              ),
            }}
          />
          <CQuoteBox>
            <h3 className="c_headingLv3">上羽絵惣 本店</h3>
            <p>
              住所：京都市下京区東洞院通高辻下ル燈籠町579
              <br />
              HP：
              <a
                href="https://www.ueba.co.jp"
                target="_blank"
                rel="noopener noreferrer"
                className="u_hoverLine"
              >
                https://www.ueba.co.jp
              </a>
              <br />
              Instagram：@uebaesou
            </p>
          </CQuoteBox>
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article07/img_chapter02.png",
            },
            imgSp: {
              src: "/assets/images/journal/article07/img_chapter02__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "02",
            },
            title: (
              <>
                <span>外に出ずとも旅を満喫、</span>
                <span>ホテルで名店の味を</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  今回宿泊するザ ロイヤルパークホテル
                  京都四条は、JR京都駅から市営地下鉄烏丸線で約4分、四条駅から徒歩約1分と便利な立地だ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter02_01.png",
              alt: "",
            }}
            caption={
              <>
                明るく開放的なロビー。壁面のアートは笹舟をモチーフにしたもので、京都らしい情景を織り込んだ繊細さが美しい
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  大通りの喧騒から一歩ホテルへ足を踏み入れると、落ち着いた雰囲気に包まれ、まるで歓迎を受けているよう。現代らしさ、京都らしさが融合されたデザインをベースにした空間は女性同士の旅にはぴったりの上質感で心地がいい。「いらっしゃいませ」と笑顔のホテルスタッフさんのおもてなしにもホッとする。周辺のおすすめスポットやちょっぴりディープな京都を聞いてみるのもいいだろう。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_02.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_03.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  1Fには京都の老舗ベーカリー「進々堂」がプロデュースする、カフェ＆ブラッセリー「ルボンヴィーヴル」も。自慢のパンと京都の素材を中心とした料理、ワイン、チーズなどが楽しめる人気店だ
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_04.png",
                  alt: "",
                },
                caption: (
                  <>
                    朝食の人気メニュー、オニオングラタンスープ。たっぷりのグリュエルチーズが入ったスープは、心も体も温まる
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_05.png",
                  alt: "",
                },
                caption: <>まるでパリのビストロに迷い込んだような店内</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    「ここ一度行ってみたかったの！
                    」とはしゃぐ私の姿に母も興味津々。朝食付きのプランだから、明日の朝は豪華なブレックファストメニューが楽しめるのだ。出来立ての卵料理や野菜たっぷりのスープなど5種の中からお好みのメインをチョイス。すべてのメニューには常時8種のパンがおかわり自由という嬉しいサービスも。焼きたてのパンの香りに包まれる、ちょっぴり贅沢で優雅な朝は想像しただけでも心が満たされる。
                  </p>
                  <p>
                    実は、ルボンヴィーヴルのおすすめは朝食だけではない。季節の果実をテーマにしたアフタヌーンティーも人気で、12〜4月のシーズンは「あまおう苺のアフタヌーンティー2023」を目当てに来店する人も多いみたい。
                  </p>
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_06.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter02_07.png",
                  alt: "",
                },
              },
            ]}
            caption={
              <>
                目の前に運ばれてくるだけで「わあ！」と思わず声をあげてしまうほど。すべて店内の厨房でつくられた自家製だ（写真は2名分）
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  <p>
                    食べ頃のあまおう苺やあまおうを使ったミルフィーユ、スコーン、カヌレなどスイーツメニューのほか、クロックムッシュやスープ、ミニバーガーなど食事系メニューも充実。ワンセットで果実の味わいと進々堂ならではの食を堪能できる、まるで夢のようなメニューだ。
                    <br />
                    一流ホテルなどでしか味わえないロンネフェルト社の紅茶を片手にホッとひと息。「ホテルの外に出なくても、京都の名店を体験できるなんてお得だね」とこっそり微笑み合う。
                  </p>
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>
                      「あまおう苺のアフタヌーンティー2023」お一人様3,500円（消費税込）は2023年4月後半までの期間限定（要予約／「あまおう苺」がなくなり次第終了）
                    </li>
                  </ul>
                </>
              ),
            }}
          />
        </LWrap>
      </section>

      <section className="u_mbLarge">
        <CChapterHeading
          data={{
            img: {
              src: "/assets/images/journal/article07/img_chapter03.png",
            },
            imgSp: {
              src: "/assets/images/journal/article07/img_chapter03__sp.png",
            },
            label: {
              en: "CHAPTER",
              number: "03",
            },
            title: (
              <>
                <span>ザ ロイヤルパークホテル </span>
                <span>京都四条で体験する、</span>
                <br />
                <span>憧れのスイートルーム</span>
              </>
            ),
          }}
        />
        <LWrap>
          <CJournalText
            data={{
              text: (
                <>
                  ロビーの素敵な雰囲気はさることながら、ホテルの客室フロアもモダンと伝統を融合させた四季を感じる趣。客室は広めでゆったりとして、全室バスルームには洗い場付きという点も母娘旅におすすめだ。久しぶりに母と過ごす時間。ちょっぴり贅沢しちゃう？…と今回はちょっと奮発してスイートルームを選んだ。スイートルームがあるのはホテル最上階９階プレミアムフロア。わずか14室のみの客室で、降り立った瞬間目に入るあでやかな京の色と情景を織り交ぜたデザインが美しい。世界有数のホスピタリティデザイン会社、ハーシュ・ベドナー・アソシエイツが⼿掛けているというからこの上質感も納得だ。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter03_01.png",
                  alt: "",
                },
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter03_02.png",
                  alt: "",
                },
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  誰もが一度は泊まってみたいと憧れながら、スイートルームは手が届きにくいから…と尻込みしてしまうところだが、予約時に価格を見て驚いた。オフシーズンは比較的手頃な価格で泊まれるのだ。想像していたよりもずっと手頃に、手の届く贅沢として宿泊できるのも、ザ
                  ロイヤルパークホテル 京都四条に決めた理由だ。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter03_03.png",
              alt: "",
            }}
            caption={
              <>
                大きな窓がある客室内は随所に京の美を感じる。落ち着きの中にも、色鮮やかで遊び心ある華やかな空間
              </>
            }
          />
          <CJournalText
            data={{
              text: (
                <>
                  2部屋のみのスイートルームは、広さは46平米以上。京の⾊を組み細⼯のように合わせたしつらえの客室が印象的だ。合計幅2,400mmのベッドが部屋の中央に置かれ、メインルーム以外にも、ドレッシングテーブルが完備されたバスルームエリアなど上質感たっぷり。プレミアムフロア限定の特典として、全プラン朝食付き、1Fの「進々堂」で引換可能なチケット、ドリップポッドなどサービスも充実しているからお得感に嬉しくなる。
                </>
              ),
            }}
          />
          <CTileImg
            size="small"
            data={[
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter03_04.png",
                  alt: "",
                },
                caption: (
                  <>
                    京焼の器でいただく京のお茶。ふとした小さなおもてなしが、日常を非日常に変えてくれる
                  </>
                ),
              },
              {
                img: {
                  src: "/assets/images/journal/article07/img_chapter03_05.png",
                  alt: "",
                },
                caption: <>肌触りのよいバスローブもご用意</>,
              },
            ]}
          />
          <CJournalText
            data={{
              text: (
                <>
                  上質なしつらえと非日常なおもてなしに「選んで正解だったね」とさっきから何度も二人で口にしている。明日のチェックアウトはプレミアムフロア特典でレイトチェックアウトだから12:00。今日は久しぶりに二人だけでゆっくりと話せそう。「最近どう？」なんて話から、ちょっぴり深い話まで、気兼ねなく母と娘水いらずの時間を過ごそう。あちこちめぐる旅もいいけれど、こんな二人の時間こそ最高の親孝行なのかもしれない。
                </>
              ),
            }}
          />
          <CSingleImg
            img={{
              src: "/assets/images/journal/article07/img_chapter03_06.png",
              alt: "",
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  春の京都を感じながら、美しい彩りを見つける旅。母と娘、二人だけで過ごす時間を京都で満喫してみては？
                </>
              ),
            }}
          />
          <CJournalText
            data={{
              text: (
                <>
                  <ul className="c_noteList">
                    <li>本記事・画像は2023年3月時点のものです。</li>
                  </ul>
                </>
              ),
            }}
          />

          <p className="u_fwb u_tac u_mt80">ザ ロイヤルパークホテル 京都四条</p>
          <CBtnList
            exClass="u_mt20"
            data={[
              {
                label: "ホテルサイト",
                color: "bgBlack",
                link: {
                  href: "https://www.royalparkhotels.co.jp/the/kyotoshijo/",
                  blank: true,
                },
                icon: "blank",
              },
            ]}
          />
        </LWrap>
      </section>

      <div className="l_sect02 u_bgGray">
        <LWrap>
          <CBtnList
            data={[
              {
                label: "ロイヤルパークホテルズJournal",
                link: {
                  href: "/pickup/#journal",
                },
                iconBefore: true,
                icon: "arrowReverse",
              },
            ]}
          />
        </LWrap>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
